<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="delete-modal-item-component final-modal">
        <div class="v-modal-content">
            <div class="v-modal-body">
                <!-- <span class="pull-right" style="cursor:pointer;" @click="closeModal()">
                    <i class="icon icon-close" style="font-size: 18px!important; color: #00448b;"></i>
                </span> -->
                <div class="v-modal-layout">
                    <div class="text-center mt-4">
                        <h1 class="fs-16">Order placed by Manager - You will not be able to edit or view</h1>
                    </div>
                </div>
                <div class="v-modal-footer text-center pt-2 mt-3 pb-4 modal-bg">
                    <button class="btn btn-danger btn-smm" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>

export default {
    props: [
        'modal_name',
    ],
    mixins: [],
    data() {
        return {
        }
    },
    methods: {
        closeModal() {
            this.$emit('hideMessagePopupModal')
        },
    },
    mounted() {

    },
    created() {
    },
    components: {
        
    }
}
</script>
<style>
.product-avatar.content-center {
    margin: 0 auto !important;
}
.delete-modal-item-component.v--modal-overlay .v--modal-box {
    top: 78px !important;
    left: 0px !important;
    max-width: 700px !important;
    width: 430px !important;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.43) !important;
    border-radius: 15px !important;
    max-height: 580px !important;
    margin: 0 auto !important;
}
.delete-modal-item-component.v--modal-overlay .v--modal-box .v-modal-layout {
    max-height: inherit !important;
}
.delete-modal-item-component .v-modal-content {
    background-color: #f2f5f8;
}
</style>
